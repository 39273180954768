import { useEffect, useState } from "react";
import { fetchCreatorDetail } from "../../../apis/creator-detail";
import { convertCountLike } from "../../../constants";
import OwnerIcon from "../../../assets/owner-logo.svg";

const PopupCreatorDetail = ({ id }) => {
  const [creator, setCreator] = useState();

  useEffect(() => {
    const fetchCreator = async () => {
      const resutl = await fetchCreatorDetail(id);
      setCreator(resutl);
    };

    if (id) fetchCreator();
  }, [id]);

  return creator ? (
    <div className="shadow-custom bg-white !rounded-[12px] p-4 w-[408px] cursor-default">
      <div className="flex items-center gap-2 mb-3">
        <a
          href={`/creator-detail/${encodeURIComponent(
            creator?.creator?.username || ""
          )}`}
          target="_self"
        >
          <img
            src={creator.creator.imgUrl || OwnerIcon}
            alt="logo creator"
            className="min-w-[72px] max-w-[72px] h-[72px] rounded-full object-cover cursor-pointer"
          />
        </a>
        <div>
          <p className="text-base font-semibold mb-1 truncate">
            {creator.creatorHub.name}
          </p>
          <p className="text-[12px] overflow-hidden truncate line-clamp-3 whitespace-normal break-all">
            {creator.creatorHub.description}
          </p>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-[120px] h-[120px] rounded-[8px] bg-light-green p-4">
          <div className="mb-2">
            <p className="text-[12px] !text-[#084F4B]">Twygs created</p>
            <p className="text-xl font-extrabold !text-[#084F4B]">
              {convertCountLike(creator.numTwygsCreated)}
            </p>
          </div>
          <div className="mb-2">
            <p className="text-[12px] !text-[#084F4B]">Total likes</p>
            <p className="text-xl font-extrabold !text-[#084F4B]">
              {convertCountLike(creator.totalLikesReceived)}
            </p>
          </div>
        </div>
        {creator?.topTwoTwygs?.map((elm) => {
          return (
            <img
              src={elm.thumbnail}
              alt="category top"
              key={elm.id}
              className="w-[120px] h-[120px] rounded-[8px] object-cover"
            />
          );
        })}
      </div>
    </div>
  ) : null;
};

export default PopupCreatorDetail;
