import React, { useState, useRef, useEffect } from "react";
import OtpInput from "../../otp-verify-code";
import { VERIFY_OTP_MODAL } from "../../constants";

const VerifyOtpModal = ({
  isOpen,
  title = VERIFY_OTP_MODAL.title,
  desc = VERIFY_OTP_MODAL.desc.email,
  errorMessage,
  btnText = VERIFY_OTP_MODAL.btText,
  isLoading,
  setErrorMessage,
  onClick,
  onClose,
  handleResendOpt,
}) => {
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(30);
  const timeRef = useRef(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    if (timeLeft <= 0) clearInterval(timer);

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  useEffect(() => {
    if (timeRef.current) {
      timeRef.current.innerText = `0:${timeLeft}`;
    }
  }, [timeLeft, timeRef]);

  const onResendOtp = () => {
    setTimeLeft(30);
    if (handleResendOpt) handleResendOpt();
  };

  const onVerify = (e) => {
    e.preventDefault();
    const convertOtp = otp.split("").splice(0, 6).join("");
    onClick(convertOtp);
  };

  const onChange = (value) => {
    setOtp(value);
    if (setErrorMessage) setErrorMessage("");
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-01-jet-black">
      <div className="bg-white rounded-[1.5rem] max-w-[480px] w-full p-12 overflow-y-auto max-h-[85vh]">
        <div className="mb-8">
          <h2 className="text-[32px] font-black mb-2">{title}</h2>
          <p className="text-sm font-normal">{desc}</p>
        </div>
        <form onSubmit={onVerify}>
          <div className={`h-${handleResendOpt ? "[6.75rem]" : "[5.375rem]"}`}>
            <div className={`w-full ${errorMessage ? "border-red-500" : ""}`}>
              <OtpInput
                value={otp}
                valueLength={6}
                onChange={onChange}
                error={!!errorMessage}
              />
            </div>
            {handleResendOpt && (
              <p
                className={`mt-5 text-center cursor-${
                  timeLeft === 0 ? "pointer" : "default"
                } ${
                  timeLeft === 0
                    ? "text-green-500 font-extrabold"
                    : "text-gray-600 font-normal"
                }`}
                onClick={timeLeft === 0 ? onResendOtp : undefined}
              >
                {timeLeft !== 0 ? (
                  <>
                    Resend OTP in{" "}
                    <span ref={timeRef} className="text-gray-600">
                      0:30
                    </span>
                  </>
                ) : (
                  "Resend OTP"
                )}
              </p>
            )}
            {errorMessage && (
              <p className="!text-red-500 mt-2 text-base">{errorMessage}</p>
            )}
          </div>
          <button
            type="submit"
            className={`w-full mt-${
              handleResendOpt ? "12" : "6"
            } py-2 bg-[#084F4B] !text-white font-bold rounded-xl text-lg h-[58px] ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading}
          >
            {btnText}
          </button>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtpModal;
