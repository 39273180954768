import { useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "@chakra-ui/react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import LogoTwygs from "../../assets/logo-twygs.svg";
import { getUserInfo } from "../../apis/twygs-detail";
import AvatarIcon from "../../assets/ava-icon.svg";
import AdvertiserIcon from "../../assets/advertiser-icon.svg";
import CreatorIcon from "../../assets/creator-icon.svg";
import SettingIcon from "../../assets/setting-icon.svg";
import LogOutIcon from "../../assets/logout-icon.svg";
import SettingNotifyIcon from "../../assets/setting-notify-icon.svg";

import { useClickOutside } from "../../hooks/useOutsideClick";
import {
  classifyDevice,
  CONTENT_TYPES,
  removeAllCookies,
} from "../../constants";
import { profileAtom } from "../Settings/atom";
import MenuIcon from "../../assets/menu-icon.svg";
import HomeIcon from "../../assets/home-icon.svg";
import ExploreIcon from "../../assets/explore-icon.svg";
import CategoryIcon from "../../assets/category-icon.svg";
import HeaderActionComponent from "./header-action.component";
import useSearchParams from "../../hooks/router/useSearchParams";
import YourProfileIcon from "../../assets/user-profile-icon.svg";
import CreatorStudioIcon from "../../assets/creator-studio-icon.svg";
import NotificationComponent from "./Notification/notification.component";
const staging = process.env.REACT_APP_IS_STAGING;

const HeaderComponent = ({ openMenu, setOpenMenu }) => {
  const [profile, setProfile] = useAtom(profileAtom);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const menuRef = useRef(null);
  const { searchParams, setSearchParams } = useSearchParams();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [collapList, setcollapList] = useState([]);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const listItem = useMemo(
    () => [
      {
        name: "Twygs Ads",
        icon: AdvertiserIcon,
        onClick: () => {
          window.open(`${process.env.REACT_APP_ADVERTISER_URL}`, "_blank");
        },
      },
      {
        name: "Creator Studio",
        icon: CreatorIcon,
        onClick: () => {
          window.open(`${process.env.REACT_APP_CREATOR_URL}`, "_blank");
        },
      },
      {
        name: "Account Settings",
        icon: SettingIcon,
        onClick: () => {
          navigate("/account-settings");
        },
      },
      {
        name: "Log Out",
        icon: LogOutIcon,
        onClick: () => {
          removeAllCookies();
          localStorage.removeItem("user");
          navigate("/");
        },
      },
    ],
    [navigate]
  );

  const listFooter = useMemo(
    () => [
      {
        name: "Twygs Home",
        href: process.env.REACT_APP_MAIN_URL,
      },
      {
        name: "Contact Us",
        href: `${process.env.REACT_APP_MAIN_URL}contact-us`,
      },
      {
        name: "Privacy Policy",
        href: `${process.env.REACT_APP_MAIN_URL}privacy-policy`,
      },
      {
        name: "Terms & Conditions",
        href: `${process.env.REACT_APP_MAIN_URL}terms-of-use`,
      },
    ],
    []
  );

  const listItemMenu = useMemo(
    () => [
      {
        name: "Home",
        icon: HomeIcon,

        href: "/",
      },
      {
        name: "Explore",
        icon: ExploreIcon,
        href: "#",
        subList: [
          {
            name: "Trending",
            type: "filter",
            href: "/?filter=Trending",
          },
          {
            name: "Top Twygs",
            type: "filter",
            href: "/?filter=Top Twygs",
          },
        ],
      },
      {
        name: "Content Types",
        icon: CategoryIcon,
        href: "#",
        subList: [
          ...CONTENT_TYPES.map((elm) => {
            return {
              ...elm,
              name: elm.name,
              type: "contentType",
              href: `/?contentType=${encodeURIComponent(elm.name)}`,
            };
          }),
        ],
      },
      {
        name: "Your Profile",
        icon: YourProfileIcon,
        href: `/creator-detail/${encodeURIComponent(user.username)}`,
        requireLogin: true,
      },
      {
        name: "Creator Studio",
        icon: CreatorStudioIcon,
        href: "#",
        subList: [
          {
            name: "Create Twygs",
            href: "/studio/create-twyg",
          },
          {
            name: "My Twygs",
            href: "/studio/my-twygs",
          },
        ],
        requireLogin: true,
      },
      {
        name: "Settings",
        icon: SettingNotifyIcon,
        href: "/notification-settings",
        requireLogin: true,
      },
    ],
    [user.username]
  );

  const token = Cookies.get(`${staging ? "staging" : ""}token`);

  useClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  useClickOutside(menuRef, () => {
    if (window.innerWidth < 1313) {
      setOpenMenu(false);
    }
  });

  const handleToggleProfileModal = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (token) {
        const result = await getUserInfo();
        setProfile(result);
        localStorage.setItem("user", JSON.stringify(result));
      }
    };
    if (token) fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="flex flex-col fixed top-0 left-0 w-full z-10 font-nunito">
      <div
        className={`bg-white w-full ${
          window.location.pathname.includes("creator-detail") ? "" : "shadow-sm"
        }`}
      >
        <div className="m-auto px-5 w-full py-2 md:py-4 flex items-center">
          <div className="relative" ref={menuRef}>
            <button
              onClick={() => {
                // if (openMenu) setcollapList([]);
                setOpenMenu(!openMenu);
              }}
              className="md:w-[86px] pr-2 md:pr-[50px] md:pl-2"
            >
              <img
                src={MenuIcon}
                alt="menu"
                className="w-5 h-5 md:w-auto md:h-auto"
              />
            </button>
            {(openMenu || classifyDevice() !== "Mobile") && (
              <div
                className={`overflow-y-scroll scrollable-div absolute z-20 top-[36px] md:top-[49px] 2xl:top-[55px] h-[calc(100vh-44px)] md:h-[calc(100vh-60px)] -left-5 pt-5 pb-5 flex flex-col gap-[8px] bg-white ${
                  openMenu
                    ? "md:w-[300px] w-[100vw] px-4"
                    : "md:w-[88px] px-[20px] items-center"
                } border border-solid border-[#F2F3F7] border-t-0 shadow-[0px_0px_0px_0px_#80808026]`}
              >
                <div className="block md:hidden">
                  <HeaderActionComponent />
                </div>
                {listItemMenu
                  .filter(
                    (elm) => !elm.requireLogin || (token && elm.requireLogin)
                  )
                  .map((item, index) => (
                    <div key={index} className={`flex flex-col gap-2`}>
                      <a
                        href={item.href}
                        target={item.target ?? "_self"}
                        className={`hover:bg-[#F0F7F2] hover:rounded-[8px] cursor-pointer flex items-center justify-between text-sm 2xl:text-base ${
                          openMenu ? "px-3 py-[8px]" : "p-[10px]"
                        } ${
                          (window.location.pathname === "/" &&
                            item.name === "Home" &&
                            !searchParams.filter &&
                            !searchParams.category &&
                            !searchParams.contentType) ||
                          (window.location.pathname === "/" &&
                            searchParams.filter &&
                            item.name === "Explore") ||
                          (window.location.pathname === "/" &&
                            searchParams.contentType &&
                            item.name === "Content Types") ||
                          (window.location.pathname === "/settings" &&
                            item.name === "Your Profile") ||
                          (window.location.pathname.includes(
                            "/creator-detail"
                          ) &&
                            item.name === "Your Profile") ||
                          (window.location.pathname.includes("studio") &&
                            item.name === "Creator Studio") ||
                          (window.location.pathname ===
                            "/notification-settings" &&
                            item.name === "Settings")
                            ? "!bg-[#A6D38D] rounded-[8px]"
                            : openMenu
                            ? ""
                            : "bg-[#F2F3F7] rounded-[8px]"
                        }`}
                        onClick={() => {
                          if (item.subList) {
                            if (openMenu) {
                              let newList = [...collapList];
                              if (collapList.includes(index))
                                newList = newList.filter(
                                  (elm) => elm !== index
                                );
                              else newList = [...newList, index];
                              setcollapList(newList);
                            } else {
                              setcollapList([index]);
                              setOpenMenu(true);
                            }
                          } else if (isMobile && !openMenu) {
                            setOpenMenu(false);
                          }
                        }}
                      >
                        <div className="flex items-center gap-[12px]">
                          <div>
                            <img src={item.icon} alt={item.icon} />
                          </div>
                          {openMenu ? (
                            <p className="font-extrabold text-[#084F4B] transition-all duration-150 ease-out whitespace-nowrap">
                              {item.name}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        {item.subList && openMenu ? (
                          <div
                            className="transition-all duration-100 ease-out"
                            style={{
                              transform: `rotate(${
                                collapList.includes(index) ? "90" : 0
                              }deg)`,
                            }}
                          >
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="cursor-pointer arrow-icon "
                            >
                              <path
                                d="M13.4277 25L22.4277 16L13.4277 7"
                                strokeWidth="2.57143"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        ) : (
                          ""
                        )}
                      </a>
                      <div
                        className="transition-all duration-150 ease-out overflow-hidden"
                        style={{
                          height: collapList.includes(index) ? "auto" : "0",
                        }}
                      >
                        {item?.subList?.map((subElm, subIndex) => {
                          return (
                            <div
                              key={subIndex}
                              className={`transition-all duration-150 ease-out overflow-hidden ${
                                openMenu ? "" : "hidden"
                              } ${
                                openMenu ? "w-full" : "w-0"
                              } hover:bg-[#F0F7F2] hover:rounded-[8px] cursor-pointer flex items-center gap-[12px] pl-[44px] ${
                                openMenu ? "px-3 py-[8px]" : "p-[10px]"
                              } ${
                                decodeURIComponent(searchParams.filter) ===
                                  subElm.name ||
                                decodeURIComponent(searchParams.category) ===
                                  subElm.name ||
                                decodeURIComponent(searchParams.contentType) ===
                                  subElm.name ||
                                window.location.pathname.includes(subElm.href)
                                  ? "!bg-[#DCEED3] rounded-[8px]"
                                  : ""
                              }`}
                              onClick={() => {
                                if (!subElm.type) {
                                  navigate(subElm.href);
                                } else {
                                  setSearchParams(
                                    subElm.type === "contentType"
                                      ? {
                                          contentType: encodeURIComponent(
                                            subElm.name
                                          ),
                                        }
                                      : subElm.type === "filter"
                                      ? {
                                          filter: encodeURIComponent(
                                            subElm.name
                                          ),
                                        }
                                      : ""
                                  );
                                  navigate(`/?${subElm.type}=${subElm.name}`);
                                }
                                if (isMobile) setOpenMenu(false);
                              }}
                            >
                              <div className="w-2 h-2 bg-[#084F4B] rounded-full" />
                              <p className="font-extrabold text-[#222928] text-sm 2xl:text-base transition-all duration-150 ease-out whitespace-nowrap">
                                {subElm.name}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                {openMenu && (
                  <div className="mt-auto flex items-center flex-wrap relative pt-4">
                    <div
                      className="absolute bg-gray-100 h-[1px] top-0 -left-3"
                      style={{ width: "calc(100% + 32px)" }}
                    />
                    {listFooter.map((elm, index) => {
                      return (
                        <a
                          key={index}
                          href={elm.href}
                          className="mr-3 creator-name"
                        >
                          <p className="text-xs 2xl:text-sm font-semibold">
                            {elm.name}
                          </p>
                        </a>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
          <a href={process.env.REACT_APP_EXPLORE_URL}>
            <img
              src={LogoTwygs}
              alt="logo"
              className="w-24 2xl:w-[200px] h-8 2xl:h-12"
            />
          </a>
          <div className="flex-1 hidden md:!block">
            <HeaderActionComponent />
          </div>
          {token && profile ? (
            <div
              className="flex items-center gap-[15px] relative ml-auto"
              ref={dropdownRef}
            >
              <NotificationComponent avatar={profile?.imgUrl || AvatarIcon} />
              <p className="text-[#084F4B] font-semibold text-sm 2xl:text-lg">
                {profile?.username}
              </p>
              <img
                onClick={handleToggleProfileModal}
                src={profile?.imgUrl || AvatarIcon}
                alt="avatar"
                className="min-w-[35px] min-h-[35px] max-w-[35px] max-h-[35px] 2xl:min-w-[40px] 2xl:min-h-[40px] 2xl:max-w-[40px] 2xl:max-h-[40px] rounded-full border border-solid border-04-anti-flash-white cursor-pointer"
              />
              <div
                className={`${
                  open ? "flex" : "hidden"
                } absolute z-20 top-[50px] right-0 p-[18px] flex flex-col gap-[8px] bg-white w-[270px] rounded-[12px] border border-solid border-[#F2F3F7] shadow-[0px_8px_12px_0px_#80808026]`}
              >
                {listItem.map((item, index) => (
                  <div
                    onClick={() => {
                      item.onClick();
                      setOpen(false);
                    }}
                    key={index}
                    className="hover:bg-[#A6D38D] hover:rounded-[8px] cursor-pointer flex items-center gap-[12px] px-[12px] py-[8px]"
                  >
                    <img src={item.icon} alt={item.icon} />
                    <p className="font-extrabold text-[#084F4B] text-sm 2xl:text-base">
                      {item.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="ml-auto flex items-center gap-1 md:gap-2">
              <a
                href={`${process.env.REACT_APP_TWYGS_AUTH_URL}signup`}
                target="_self"
                id="btn"
                className="cursor-pointer flex items-center justify-center bg-white h-[36px] 2xl:h-[40px] min-w-[90px] md:min-w-[120px] w-full custom-color border custom-border-dark-green !rounded-[40px] text-sm 2xl:text-base font-black hover:opacity-80"
              >
                Get started
              </a>
              <a
                href={`${process.env.REACT_APP_TWYGS_AUTH_URL}login`}
                target="_self"
                id="btn"
                className="cursor-pointer bg-custom h-[36px] 2xl:h-[40px] flex items-center justify-center min-w-[90px] md:min-w-[120px] w-full !rounded-[40px] !text-white text-sm 2xl:text-base font-black hover:opacity-80"
              >
                Login
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
