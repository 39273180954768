import { FormProvider, useForm, useFormContext } from "react-hook-form";
import EyeOff from "../../../assets/eye-off.svg";
import EyeOn from "../../../assets/eye-on.svg";
import SuccessfullyIcon from "../../../assets/successful.svg";
import PwdCheckIcon from "../../../assets/pwd-check-icon.svg";
import PwdCircleNotiIconRed from "../../../assets/pwd-circle-noti-icon-red.svg";

import SuccessfullyModal from "../../ModalSuccess";

import { Fragment, useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordSchema } from "../schema";
import { updatePassword } from "../../../apis/settings.api";

const ChangePassword = ({ profileData }) => {
  const [showPw, setShowPw] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercaseAndLowercase: false,
    number: false,
    specialChar: false,
  });

  const [hasNewPasswordBeenFocused, setHasNewPasswordBeenFocused] =
    useState(false);
  const [hasConfirmPasswordBeenFocused, setHasConfirmPasswordBeenFocused] =
    useState(false);

  const methods = useForm({
    resolver: yupResolver(passwordSchema),
    mode: "onChange",
  });

  const { errors } = methods.formState;
  const newPassword = methods.watch("newPassword", "");
  const confirmPassword = methods.watch("confirmPassword", "");

  useEffect(() => {
    setPasswordRequirements({
      length: newPassword.length >= 8 && newPassword.length <= 64,
      uppercaseAndLowercase:
        /[A-Z]/.test(newPassword) && /[a-z]/.test(newPassword),
      number: /\d/.test(newPassword),
      specialChar: /[!@#$%^&*()_+}{"':;?/>.<,]/.test(newPassword),
    });

    if (hasConfirmPasswordBeenFocused) {
      methods.trigger("confirmPassword");
    }
  }, [newPassword, methods.trigger]);

  useEffect(() => {
    if (confirmPassword && hasConfirmPasswordBeenFocused) {
      methods.trigger("confirmPassword");
    }
  }, [confirmPassword, methods, hasConfirmPasswordBeenFocused]);

  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState("");

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await updatePassword(data);
      setIsLoading(false);
      setModal("success-modal");
      methods.reset();
      setHasConfirmPasswordBeenFocused(false);
    } catch (err) {
      window.dataLayer.push({
        event: "error",
        error_type: "password error",
        error_message: err?.data?.message,
      });
      setIsLoading(false);
      if (err?.response?.data?.statusCode === 400) {
        if (
          !(err?.response?.data?.message === "New password must be difference")
        )
          methods.setError("currentPassword", {
            type: "manual",
            message: err?.response?.data?.message,
          });
        else {
          methods.setError("newPassword", {
            type: "manual",
            message: "New password must be different from current password",
          });
        }
      }
    }
  };

  const renderModal = () => {
    if (modal === "success-modal")
      return (
        <SuccessfullyModal
          srcImg={SuccessfullyIcon}
          bgColorOverlay={"#333333"}
          titleBtn="Done"
          title="Password updated successfully"
          isOpen={true}
          onClick={() => {
            setModal("");
          }}
        />
      );
  };

  if (!profileData?.email) {
    return null;
  }

  return (
    <Fragment>
      <div className="mt-[24px] xl:max-w-[80%] max-w-[100%]">
        <p className="text-[28px] 2xl:text-[32px] !font-montserrat font-extrabold mb-1">
          Password
        </p>
        <p className="!text-02-dim-gray mb-8 text-sm 2xl:text-base">
          Please enter your current password to change your password.
        </p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex items-center min-h-[42px] pb-6 2xl:pb-8 xl:pr-[72px] border-b border-light-300">
              <label className="font-semibold text-sm 2xl:text-base w-[30%]">
                Current password
              </label>
              <div className="w-[70%] flex ">
                <div className="w-full">
                  <div className="relative">
                    <input
                      {...methods.register("currentPassword")}
                      className="w-full border border-solid border-04-anti-flash-white outline-none h-[38px] 2xl:h-[42px] text-sm 2xl:text-base pl-[14px] pr-6 rounded-[8px]"
                      type={showPw.currentPassword ? "text" : "password"}
                      placeholder="Enter current password"
                    />
                    <img
                      onClick={() => {
                        setShowPw({
                          ...showPw,
                          currentPassword: !showPw.currentPassword,
                        });
                      }}
                      className="w-4 h-4 absolute right-[13px] bottom-[11px] 2xl:bottom-[13px] cursor-pointer"
                      alt=""
                      src={showPw.currentPassword ? EyeOn : EyeOff}
                    />
                  </div>
                  {errors.currentPassword && (
                    <p className="text-sm !text-error-red mt-2">
                      {errors.currentPassword.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-start min-h-[42px] py-6 2xl:py-8 xl:pr-[72px] border-b border-light-300">
              <label className="font-semibold text-sm 2xl:text-base w-[30%]">
                New password
              </label>
              <div className="w-[70%] flex flex-col">
                <div className="w-full">
                  <div className="relative">
                    <input
                      {...methods.register("newPassword")}
                      className="w-full border border-solid border-04-anti-flash-white outline-none h-[38px] 2xl:h-[42px] text-sm 2xl:text-base pl-[14px] pr-6 rounded-[8px]"
                      type={showPw.newPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      onFocus={() => setHasNewPasswordBeenFocused(true)}
                    />
                    <img
                      onClick={() => {
                        setShowPw({
                          ...showPw,
                          newPassword: !showPw.newPassword,
                        });
                      }}
                      className="w-4 h-4 absolute right-[13px] bottom-[11px] 2xl:bottom-[13px] cursor-pointer"
                      alt=""
                      src={showPw.newPassword ? EyeOn : EyeOff}
                    />
                  </div>
                  {errors.newPassword && (
                    <p className="text-sm !text-error-red mt-2">
                      {errors.newPassword.message}
                    </p>
                  )}
                  <div className="mt-2 text-sm">
                    <p className="!text-[#9E9E9E]">
                      As a reminder, your password must include:
                    </p>
                    <ul className="mt-2 pl-4 list-disc list-inside">
                      <li className="flex items-center">
                        <img
                          src={
                            passwordRequirements.length
                              ? PwdCheckIcon
                              : PwdCircleNotiIconRed
                          }
                          alt=""
                          className="w-3.5 h-3.5 mr-2"
                        />
                        <span className={"!text-[#9E9E9E]"}>
                          8-64 characters
                        </span>
                      </li>
                      <li className="flex items-center">
                        <img
                          src={
                            passwordRequirements.uppercaseAndLowercase
                              ? PwdCheckIcon
                              : PwdCircleNotiIconRed
                          }
                          alt=""
                          className="w-3.5 h-3.5 mr-2"
                        />
                        <span className={"!text-[#9E9E9E]"}>
                          both uppercase and lowercase letters
                        </span>
                      </li>
                      <li className="flex items-center">
                        <img
                          src={
                            passwordRequirements.number
                              ? PwdCheckIcon
                              : PwdCircleNotiIconRed
                          }
                          alt=""
                          className="w-3.5 h-3.5 mr-2"
                        />
                        <span className={"!text-[#9E9E9E]"}>
                          at least one number
                        </span>
                      </li>
                      <li className="flex items-center">
                        <img
                          src={
                            passwordRequirements.specialChar
                              ? PwdCheckIcon
                              : PwdCircleNotiIconRed
                          }
                          alt=""
                          className="w-3.5 h-3.5 mr-2"
                        />
                        <span className={"!text-[#9E9E9E]"}>
                          at least one special character
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center min-h-[42px] py-6 2xl:py-8 xl:pr-[72px] border-b border-light-300">
              <label className="font-semibold text-sm 2xl:text-base w-[30%]">
                Confirm new password
              </label>
              <div className="w-[70%] flex ">
                <div className="w-full ">
                  <div className="relative">
                    <input
                      {...methods.register("confirmPassword")}
                      className="w-full border border-solid border-04-anti-flash-white outline-none h-[38px] 2xl:h-[42px] text-sm 2xl:text-base pl-[14px] pr-6 rounded-[8px]"
                      type={showPw.confirmPassword ? "text" : "password"}
                      placeholder="Enter confirm password"
                      onFocus={() => setHasConfirmPasswordBeenFocused(true)}
                    />
                    <img
                      onClick={() => {
                        setShowPw({
                          ...showPw,
                          confirmPassword: !showPw.confirmPassword,
                        });
                      }}
                      className="w-4 h-4 absolute right-[13px] bottom-[11px] 2xl:bottom-[13px] cursor-pointer"
                      alt=""
                      src={showPw.confirmPassword ? EyeOn : EyeOff}
                    />
                  </div>

                  {errors.confirmPassword && (
                    <p className="text-sm !text-error-red mt-2">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-[12px] mt-6 2xl:mt-8">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  methods.reset();
                  setHasConfirmPasswordBeenFocused(false);
                }}
                className="border-2 border-solid px-[20px] h-[36px] 2xl:h-[40px] text-sm 2xl:text-base rounded-[8px] font-black !text-[#084F4B] border-[#084F4B] hover:opacity-80"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-[#084F4B] px-[20px] h-[36px] 2xl:h-[40px] text-sm 2xl:text-base rounded-[8px] font-black !text-white hover:opacity-80"
                disabled={isLoading}
              >
                {isLoading ? "Updating..." : "Update Password"}
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      {renderModal()}
    </Fragment>
  );
};

export default ChangePassword;
