import { useState, useMemo } from "react";
import { useAtom } from "jotai";
import { getQrCodeUrl, sendOtpEmail, setup2FA } from "../../../apis/two-factor";
import ChooseAuthentication from "../../../components/Profile/two-factor-authentication/choose-authentication.component";
import { VERIFY_OTP_MODAL } from "../../../components/Profile/constants";
import { profileAtom } from "../../../components/Settings/atom";
import VerifyOtpModal from "../../../components/Profile/modal/verify-otp";
import EmailVerify from "../../../components/Profile/two-factor-authentication/email-setup.component";
import AuthenticatorSetup from "../../../components/Profile/two-factor-authentication/authenticator-app-setup";

const TwoFactorAuthentication = () => {
  const [profile] = useAtom(profileAtom);
  const [twoFAType, setTwoFAType] = useState("");
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    desc: "",
    btnText: "",
    onClick: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [appData, setAppData] = useState({
    email: "",
    twoFactorSecretKey: "",
    otpAuthUrl: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const option = useMemo(
    () => [
      {
        title: "Email Verification",
        value: "EMAIL_OTP",
        active: profile?.twoFAType === "EMAIL_OTP",
      },
      {
        title: "Authenticator App",
        value: "AUTHENTICATOR_APP_OTP",
        active: profile?.twoFAType === "AUTHENTICATOR_APP_OTP",
      },
    ],
    [profile?.twoFAType]
  );

  const handleSetup = async (selectType) => {
    try {
      switch (selectType) {
        case "EMAIL_OTP":
          if (profile?.isEnable2FA) {
            handleModal({
              isOpen: true,
              desc: VERIFY_OTP_MODAL.desc.app,
              onClick: (value) => handleVerify(value, selectType),
            });
          } else {
            const payload = {
              twoFactorAuthType: selectType,
            };
            await setup2FA(payload);
            setTwoFAType(selectType);
          }

          break;
        case "AUTHENTICATOR_APP_OTP":
          if (profile?.isEnable2FA) {
            const payload = {
              email: profile.email,
            };
            await sendOtpEmail(payload);
            handleModal({
              isOpen: true,
              onClick: (value) => handleVerify(value, selectType),
            });
          } else {
            const payload = {
              twoFactorAuthType: selectType,
            };
            const data = await setup2FA(payload);
            if (data) {
              setAppData({
                email: data.email,
                twoFactorSecretKey: data.twoFactorSecretKey,
                otpAuthUrl: data.otpAuthUrl,
              });
            }
            setTwoFAType(selectType);
          }
          break;
        default:
          break;
      }
    } catch (e) {
      setErrorMessage(VERIFY_OTP_MODAL.errorMessage);
    }
  };

  const handleVerify = async (otp, type) => {
    try {
      setIsLoading(true);
      const payload = {
        twoFactorAuthType: type,
        otp,
      };
      const { data } = await setup2FA.mutateAsync(payload);
      if (data.success) {
        if (type === "AUTHENTICATOR_APP_OTP") {
          const info = data.data;
          setAppData({
            email: info.email,
            twoFactorSecretKey: info.twoFactorSecretKey,
            otpAuthUrl: info.otpAuthUrl,
          });
        }
      }
      setTwoFAType(type);
      handleModal();
    } catch (e) {
      setErrorMessage(VERIFY_OTP_MODAL.errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenView = (selectType) => {
    if (selectType === "AUTHENTICATOR_APP_OTP") {
      handleModal({
        isOpen: true,
        desc: VERIFY_OTP_MODAL.desc.app,
        onClick: (value) => handleVerifyView(value),
      });
    }
  };

  const handleVerifyView = async (otp) => {
    try {
      setIsLoading(true);
      const payload = {
        otp,
      };
      const { data } = await getQrCodeUrl.mutateAsync(payload);
      if (data.success) {
        const info = data.data;
        setAppData({
          email: info.email,
          twoFactorSecretKey: info.twoFactorSecretKey,
          otpAuthUrl: info.otpAuthUrl,
        });
      }
      setTwoFAType("AUTHENTICATOR_APP_OTP");
      handleModal();
    } catch (e) {
      setErrorMessage(VERIFY_OTP_MODAL.errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModal = (value) => {
    setModal({
      isOpen: value?.isOpen || false,
      title: value?.title,
      desc: value?.desc,
      btnText: value?.btnText,
      onClick: value?.onClick || null,
    });
    setErrorMessage("");
  };

  const handleCloseModal = () => {
    handleModal();
  };

  return (
    <div
      className="bg-06-seasalt-white"
      style={{ minHeight: "calc(100vh - 80px)" }}
    >
      <div className="m-auto px-5 py-5 w-full md:py-8">
        <div className="">
          {!twoFAType && (
            <ChooseAuthentication
              option={option}
              onOpenView={handleOpenView}
              onSetup={handleSetup}
            />
          )}
          {twoFAType === "EMAIL_OTP" && <EmailVerify />}
          {twoFAType === "AUTHENTICATOR_APP_OTP" && (
            <AuthenticatorSetup appData={appData} />
          )}
        </div>
        {modal?.isOpen && (
          <VerifyOtpModal
            isOpen={modal?.isOpen}
            title={modal?.title}
            desc={modal?.desc}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            onClick={(value) => {
              modal?.onClick(value);
            }}
            onClose={handleCloseModal}
            isLoading={isLoading}
          />
        )}
      </div>
    </div>
  );
};

export default TwoFactorAuthentication;
