import { useEffect, useState } from "react";
import { classifyDevice, convertCountLike } from "../../../constants";
import { getRelativeContent } from "../../../apis/twygs-detail";
import LikeIcon from "../../../assets/like-icon.svg";

const RightComponent = ({ idTwyg, isAllow }) => {
  const [pagination, setPagination] = useState({
    limit: 14,
    page: 1,
    filter: `${isAllow ? "related" : "top"}`, //"related",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [twygs, setTwygs] = useState({
    twygs: [],
    endOfVideoRelativeContents: [],
  });
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isAllow === undefined) return;
    const fetchRelativeTwyg = async () => {
      try {
        if (!isMounted) {
          if (isAllow === true && pagination.filter === "top")
            setPagination({ ...pagination, filter: "related", page: 1 });
          setIsMounted(true);
        }
        setIsLoading(true);
        const response = await getRelativeContent(
          {
            page: pagination.page,
            limit: pagination.limit,
            filter: pagination.filter,
          },
          pagination.page === 1
            ? { twygsId: idTwyg }
            : {
                excludeTwygsId:
                  twygs.endOfVideoRelativeContents.length > 0
                    ? [
                        ...twygs.endOfVideoRelativeContents,
                        twygs.twygs[0],
                        twygs.twygs[1],
                        twygs.twygs[2],
                      ].map((elm) => elm.twygsId)
                    : [twygs.twygs[0], twygs.twygs[1], twygs.twygs[2]].map(
                        (elm) => elm.twygsId
                      ),
                twygsId: idTwyg,
              }
        );
        setTwygs(
          pagination.page === 1
            ? response
            : {
                ...twygs,
                twygs: [...twygs.twygs, ...response.twygs],
              }
        );
        if (response.endOfVideoRelativeContents) {
          const elmVideo = document.getElementById("video-twyg");
          elmVideo.addEventListener("play", function () {
            const containerVideo = document.getElementById(
              "related-content-end-video"
            );
            if (containerVideo) {
              containerVideo.remove();
            }
          });

          elmVideo.addEventListener("timeupdate", function () {
            const containerVideo = document.getElementById(
              "related-content-end-video"
            );
            if (containerVideo) {
              containerVideo.remove();
            }
          });

          elmVideo.addEventListener("ended", function () {
            const containerVideo = document.getElementById("container-video");
            if (response.endOfVideoRelativeContents.length > 0) {
              const divRelatedCreator = document.createElement("div");
              divRelatedCreator.id = "related-content-end-video";
              divRelatedCreator.style.cssText = `display: flex; position: absolute; top: 50%; transform: translateY(-50%); width: 100%; justify-content: center; align-items: center; gap: 16px;`;

              let htmlRelated = "";

              for (
                let index = 0;
                index < response.endOfVideoRelativeContents.length;
                index++
              ) {
                const detail = response.endOfVideoRelativeContents[index];
                htmlRelated += `
                 <a href="${window.location.origin}/twygs-detail?id=${
                  detail.twygsId
                }" alt="another twyg" class="related-twyg-item" style="
                      width: 40%; position: relative;
                      height: ${
                        containerVideo.clientWidth * 0.4 * 0.5625
                      }px; background-repeat: no-repeat;
                      border: 1px solid #FAFAFA;
                      border-radius: 8px;
                      background-size: cover;
                      background-position: center;
                      cursor: poniter;
                      background-image: url(${detail.thumbnailUrl});" >
                  <div>
                    <div style="position: absolute; left: 10px; top: 10px; width: fit-content; border-radius: 4px; background: rgba(0, 0, 0, 0.30); padding: 4px 8px;">
                      <p class="ad-title" style="color: white !important; font-size: 12px !important; font-weight: 800 !important; max-width: fit-content;">${
                        detail.twygsName
                      }</p>
                    </div>
                    <div style="display: flex; align-items: center; justify-content: center; position: absolute; right: 10px; bottom: 10px; width: fit-content; border-radius: 4px; background: rgba(0, 0, 0, 0.30); padding: 4px 8px;">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="film-02" clip-path="url(#clip0_3801_3253)">
                      <path id="Icon" d="M9.99935 18.3334V1.66675M5.83268 18.3334V14.1667M5.83268 5.83342V1.66675M14.166 18.3334V14.1667M14.166 5.83342V1.66675M1.66602 5.83342H18.3327M1.66602 14.1667H18.3327M18.3327 14.3334V5.66675C18.3327 4.26662 18.3327 3.56655 18.0602 3.03177C17.8205 2.56137 17.4381 2.17892 16.9677 1.93923C16.4329 1.66675 15.7328 1.66675 14.3327 1.66675L5.66602 1.66675C4.26588 1.66675 3.56582 1.66675 3.03104 1.93923C2.56063 2.17892 2.17818 2.56137 1.9385 3.03177C1.66602 3.56655 1.66602 4.26662 1.66602 5.66675L1.66602 14.3334C1.66602 15.7335 1.66602 16.4336 1.9385 16.9684C2.17818 17.4388 2.56063 17.8212 3.03104 18.0609C3.56582 18.3334 4.26588 18.3334 5.66602 18.3334H14.3327C15.7328 18.3334 16.4329 18.3334 16.9677 18.0609C17.4381 17.8212 17.8205 17.4388 18.0602 16.9684C18.3327 16.4336 18.3327 15.7335 18.3327 14.3334Z" stroke="#FFFFFF" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_3801_3253">
                        <rect width="20" height="20" fill="white"/>
                      </clipPath>
                    </defs>
                </svg>
                    </div>
                  </div>
                </a>`;
              }
              divRelatedCreator.innerHTML = htmlRelated;
              containerVideo.appendChild(divRelatedCreator);
            }
          });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    if (idTwyg) fetchRelativeTwyg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idTwyg, pagination.limit, pagination.page, pagination.filter]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollable =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrolled = window.scrollY;
      if (scrolled >= scrollable - 100 && !isLoading) {
        setPagination((pre) => {
          return {
            ...pre,
            page: pre.page + 1,
          };
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup: remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <div id="left-content-container" className="flex !flex-col">
      <div className="flex items-center gap-3 mb-[20px]">
        {isAllow ? (
          <div
            id="btn"
            className={`cursor-pointer ${
              pagination.filter === "related" || pagination.filter === undefined
                ? "bg-custom !text-white"
                : "bg-mid-gray !text-dark-green"
            } h-[36px] 2xl:h-[40px] w-fit-content px-5 !rounded-[40px] text-sm font-black hover:opacity-90`}
            onClick={() =>
              setPagination({ ...pagination, filter: "related", page: 1 })
            }
          >
            Related
          </div>
        ) : (
          ""
        )}
        <div
          id="btn"
          className={`cursor-pointer ${
            pagination.filter === "top"
              ? "bg-custom !text-white"
              : "bg-mid-gray text-dark-green"
          } h-[36px] 2xl:h-[40px] w-fit-content px-5 !rounded-[40px] text-sm font-black hover:opacity-90`}
          onClick={() =>
            setPagination({ ...pagination, filter: "top", page: 1 })
          }
        >
          Top
        </div>
        <div
          id="btn"
          className={`cursor-pointer ${
            pagination.filter === "trending"
              ? "bg-custom !text-white"
              : "bg-mid-gray text-dark-green"
          } h-[36px] 2xl:h-[40px] w-fit-content px-5 !rounded-[40px] text-sm font-black hover:opacity-90`}
          onClick={() =>
            setPagination({ ...pagination, filter: "trending", page: 1 })
          }
        >
          Trending
        </div>
      </div>
      <div className="flex flex-col gap-5">
        {isLoading
          ? Array.from({ length: pagination.limit }).map((_, index) => (
              <Skeleton key={index} />
            ))
          : twygs.twygs.map((elm, index) => {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid

                <div className="flex gap-4 items-center" key={index}>
                  <div className="relative">
                    <a
                      alt="another twyg"
                      href={`/twygs-detail?id=${
                        elm.twygsId ?? elm.id
                      }&platform=twygs${
                        isAllow ? `&previousId=${idTwyg}` : ""
                      }`}
                    >
                      <img
                        src={elm.thumbnailUrl ?? elm.thumbnail}
                        alt="twygs item"
                        className="object-cover shadow-md overflow-hidden max-w-[100px] max-h-[100px] min-h-[100px] min-w-[100px] 2xl:max-w-[120px] 2xl:max-h-[120px] 2xl:min-h-[120px] 2xl:min-w-[120px] rounded-[12px]"
                      />
                    </a>
                  </div>
                  <div className="flex flex-col gap-[8px] max-w-[calc(100% - 136px)]">
                    <a
                      alt="another twyg"
                      href={`/twygs-detail?id=${
                        elm.twygsId ?? elm.id
                      }&platform=twygs${
                        isAllow ? `&previousId=${idTwyg}` : ""
                      }`}
                      title={elm.twygsName ?? elm.name}
                    >
                      <p className="text-sm 2xl:text-base overflow-hidden truncate line-clamp-1 2xl:line-clamp-2 whitespace-normal break-words font-extrabold">
                        {elm.twygsName ?? elm.name}
                      </p>
                    </a>
                    {elm.ownerUsername ? (
                      <a
                        href={`/creator-detail/${encodeURIComponent(
                          elm.ownerUsername
                        )}`}
                        title={elm.ownerUsername}
                        className="creator-name"
                      >
                        <div className="py-2 px-3 rounded-[40px] bg-04-anti-flash-white text-[12px] h-8 creator-name">
                          <p>{elm.category ?? elm.contentCategory}</p>
                        </div>
                      </a>
                    ) : (
                      <p className="text-[12px] font-semibold">
                        {elm.description}
                      </p>
                    )}
                    <div className="flex gap-4">
                      <div className="flex gap-3 items-center text-[12px] font-semibold">
                        <a
                          href={`/?category=${encodeURIComponent(
                            elm.category ?? elm.contentCategory
                          )}`}
                        >
                          <div className="py-2 px-3 rounded-[40px] bg-[#e6e7eb] text-[12px] h-8 creator-name">
                            <p>{elm.category ?? elm.contentCategory}</p>
                          </div>
                        </a>
                        <div className="flex items-center gap-1">
                          <img
                            src={LikeIcon}
                            alt="Like icon"
                            className="w-5 h-5"
                          />
                          {convertCountLike(elm.numberOfLike)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

const Skeleton = () => {
  return (
    <div className="flex gap-4 items-center animate-pulse">
      <div className="bg-gray-300 max-w-[100px] max-h-[100px] min-w-[100px] min-h-[100px] 2xl:max-w-[120px] 2xl:max-h-[120px] 2xl:min-w-[120px] 2xl:min-h-[120px] rounded-[12px]" />
      <div className="flex flex-col gap-[8px] w-full">
        <div className="bg-gray-300 h-6 w-full rounded" />
        <div className="bg-gray-300 h-4 w-3/4 rounded" />
        <div className="flex gap-4">
          <div className="bg-gray-300 h-8 w-24 rounded" />
          <div className="bg-gray-300 h-8 w-16 rounded" />
        </div>
      </div>
    </div>
  );
};

export default RightComponent;
