import { useEffect, useRef, useState } from "react";
import DropzoneComponent from "../../Dropzone";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ALLOWED_FILE_TYPES,
  GENDER,
  getContentType,
  MAX_FILE_SIZE,
} from "../../../constants";
import { resignUrl, updateProfile } from "../../../apis/settings.api";
import axios from "axios";
import AvaIcon from "../../../assets/ava-icon.svg";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { profileSchema } from "../schema";
import { profileAtom } from "../atom";
import { useAtom } from "jotai";
import BirthdayCalendarComponent from "../../BirthdayCalendar";
import ArrowDownIconSolid from "../../../assets/arrow-down-icon.svg";
import { useClickOutside } from "../../../hooks/useOutsideClick";
import SuccessfullyModal from "../../ModalSuccess";
import SuccessfullyIcon from "../../../assets/successful.svg";
import ErrorIcon from "../../../assets/failed-to-load.svg";

export const ChangeInfo = ({ profileData, setProfileData }) => {
  const [fileImg, setFileImg] = useState({});
  const [error, setError] = useState(false);
  const [modal, setModal] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);

  useClickOutside(dropdownRef, () => {
    setIsMenuOpen(false);
  });

  const [, setProfile] = useAtom(profileAtom);

  const methods = useForm({
    resolver: yupResolver(profileSchema),
  });

  const { errors } = methods.formState;

  const gender = useWatch({
    name: "gender",
    control: methods.control,
    defaultValue: GENDER[0],
  });

  const handleSelectGender = (value) => {
    setIsMenuOpen(false);
    methods.setValue("gender", value);
  };

  const validateFiles = async (file) => {
    let messageError = "";
    const validFiles = file[0];

    if (!ALLOWED_FILE_TYPES.includes(validFiles?.type)) {
      messageError = "Please ensure your file type is PNG, JPG, JPEG or GIF";
    }

    if (validFiles?.size > MAX_FILE_SIZE) {
      messageError = "Please ensure your file size is less than 30MB";
    }

    try {
      if (!messageError) {
        const { data } = await resignUrl({
          fileName: encodeURIComponent(validFiles?.name.toLowerCase()),
        });

        const url = data?.url;
        await axios.put(url, validFiles, {
          headers: {
            "content-disposition": "inline",
            "Content-Type": getContentType(validFiles?.name.toLowerCase()),
          },
        });
        setError(false);
      }
    } catch (err) {
      messageError =
        "An error occurred while uploading the image. Please choose another image!";
      setError(true);
    }
    const result = {
      file: validFiles,
      fileName: validFiles?.name || null,
      messageError,
      url: messageError ? "" : URL.createObjectURL(validFiles),
    };
    setFileImg(result);
  };

  const handleFileInputChange = (event) => {
    const newFiles = [...event.target.files];
    validateFiles(newFiles);
    setError(false);
  };

  const onSubmit = async (data) => {
    try {
      let payload = {
        firstName: data.firstName,
        lastName: data.lastName,
        birthday: data.birthday,
        gender: gender.value,
        username: data.username,
      };

      if (fileImg?.fileName) {
        payload = {
          ...payload,
          fileName: encodeURIComponent(fileImg.fileName.toLowerCase()),
        };
      }

      setIsLoading(true);
      const res = await updateProfile(payload);
      setIsLoading(false);
      if (res) {
        const newData = {
          ...res,
          imgUrl: fileImg?.fileName ? res.imgUrl : profileData?.imgUrl,
        };
        setModal("success-modal");
        setProfileData(newData);
        setProfile(newData);
      }
    } catch (error) {
      window.dataLayer.push({
        event: "error",
        error_type: "profile error",
        error_message: error?.data?.message,
      });
      setModal("error-modal");
    }
  };

  const renderModal = () => {
    switch (modal) {
      case "success-modal":
        return (
          <SuccessfullyModal
            srcImg={SuccessfullyIcon}
            bgColorOverlay={"#333333"}
            titleBtn="Done"
            title="Profile updated successfully"
            isOpen={true}
            onClick={() => {
              setModal("");
            }}
          />
        );
      case "error-modal":
        return (
          <SuccessfullyModal
            srcImg={ErrorIcon}
            bgColorOverlay={"#333333"}
            titleBtn="Close"
            title="Something went wrong"
            isOpen={true}
            onClick={() => {
              setModal("");
            }}
          />
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (profileData) {
      const gender =
        GENDER.find((item) => item.value === profileData.gender) || GENDER[0];

      methods.reset({
        ...profileData,
        gender,
      });
    }
  }, [methods, profileData]);

  return (
    <div className="mt-[24px] xl:max-w-[80%] max-w-[100%]">
      <p className="text-[28px] 2xl:text-[32px] !font-montserrat font-extrabold">
        Profile
      </p>
      <p className="!text-02-dim-gray mt-[4px] text-sm 2xl:text-base">
        Update current profile details
      </p>
      <div className="mt-[32px]">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-[24px] 2xl:gap-[32px]">
              <div className="flex pb-[24px] 2xl:pb-[32px] border-b border-light-300 xl:pr-[72px] ">
                <div className="w-[30%] pr-[20px]">
                  <p className="font-semibold text-sm 2xl:text-base">
                    Your photo <i className="!text-02-dim-gray">(optional)</i>
                  </p>
                  <p className="!text-02-dim-gray text-xs 2xl:text-sm">
                    This will be displayed on your profile.
                  </p>
                </div>
                <div className="flex gap-[20px] ml-auto w-[70%]">
                  <img
                    className="p-1 border border-light-300 shadow-md min-h-16 min-w-16 max-h-16 max-w-16 rounded-full object-cover"
                    alt=""
                    src={
                      fileImg?.url
                        ? fileImg.url
                        : methods.getValues("imgUrl") || AvaIcon
                    }
                  />
                  <div className="w-full cursor-pointer">
                    <DropzoneComponent
                      title="Click to upload"
                      desc="File types supported: PNG, JPG, JPEG, GIF. Max size: 30MB"
                      onChange={handleFileInputChange}
                      validateFiles={validateFiles}
                      acceptFileTypes={
                        "image/png, image/jpeg, image/jpg, image/gif"
                      }
                    />
                    {fileImg?.messageError && (
                      <p className="mt-2 text-sm !text-error-red">
                        {fileImg.messageError}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex pb-[24px] 2xl:pb-[32px] border-b border-light-300 xl:pr-[72px] items-center">
                <div className="w-[30%]">
                  <p className="font-semibold text-sm 2xl:text-base">Name</p>
                </div>
                <div className="flex gap-[20px] ml-auto w-[70%]">
                  <div className="w-full">
                    <input
                      {...methods.register("firstName", { required: true })}
                      placeholder="Enter first name"
                      className="w-full border border-solid border-04-anti-flash-white outline-none h-[38px] 2xl:h-[42px] text-sm 2xl:text-base px-[14px] rounded-[8px]"
                    />
                    {errors.firstName && (
                      <p className="text-sm !text-error-red mt-2">
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <input
                      {...methods.register("lastName", { required: false })}
                      placeholder="Enter last name"
                      className="w-full border border-solid border-04-anti-flash-white outline-none h-[38px] 2xl:h-[42px] px-[14px] text-sm 2xl:text-base rounded-[8px]"
                    />
                    {errors.lastName && (
                      <p className="text-sm !text-error-red mt-2">
                        {errors.lastName.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex pb-[24px] 2xl:pb-[32px] border-b border-light-300 xl:pr-[72px] items-center">
                <div className="w-[30%]">
                  <p className="font-semibold text-sm 2xl:text-base">
                    Username
                  </p>
                </div>
                <div className="ml-auto w-[70%]">
                  <input
                    {...methods.register("username", {
                      required: true,
                    })}
                    placeholder="Your username"
                    className="w-full border border-solid border-04-anti-flash-white outline-none text-sm 2xl:text-base h-[38px] 2xl:h-[42px] px-[14px] rounded-[8px]"
                  />
                  {errors.username && (
                    <p className="text-sm !text-error-red mt-2">
                      {errors.username.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex pb-[24px] 2xl:pb-[32px] border-b border-light-300 xl:pr-[72px] items-center">
                <div className="w-[30%]">
                  <p className="font-semibold text-sm 2xl:text-base">
                    Email address
                  </p>
                </div>
                <div className="flex gap-[20px] ml-auto w-[70%]">
                  <input
                    {...methods.register("email")}
                    readOnly
                    placeholder="Your email address"
                    className="w-full border border-solid border-04-anti-flash-white opacity-[0.7] text-sm 2xl:text-base outline-none h-[38px] 2xl:h-[42px] px-[14px] rounded-[8px]"
                  />
                </div>
              </div>
              <div className="flex pb-[24px] 2xl:pb-[32px] border-b border-light-300 xl:pr-[72px] items-center">
                <div className="w-[30%]">
                  <p className="font-semibold text-sm 2xl:text-base">
                    Birthday
                  </p>
                </div>
                <div className="flex gap-[20px] ml-auto w-[70%]">
                  <BirthdayCalendarComponent
                    name="birthday"
                    placeholder="MM/DD/YYYY"
                  />
                  {errors.birthday && (
                    <p className="text-sm !text-error-red mt-2">
                      {errors.birthday.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex pb-[24px] 2xl:pb-[32px] border-b border-light-300 xl:pr-[72px] items-center">
                <div className="w-[30%]">
                  <p className="font-semibold text-sm 2xl:text-base">Gender</p>
                </div>
                <div className="flex gap-[20px] ml-auto w-[70%]">
                  <div className="relative w-full">
                    <button
                      className="focus:border-[#A6D38D] w-full flex items-center gap-2 border bg-light.100 rounded-[8px] px-[14px] py-2"
                      onClick={(e) => {
                        setIsMenuOpen(!isMenuOpen);
                        e.preventDefault();
                      }}
                    >
                      <img src={gender?.icon} alt="icon" className="w-5 h-5" />
                      <span className="text-sm 2xl:text-base font-medium">
                        {gender?.value}
                      </span>
                      <img
                        src={ArrowDownIconSolid}
                        className={`w-5 h-5 transition-transform ml-auto ${
                          isMenuOpen ? "transform rotate-180" : ""
                        }`}
                        alt="arrow"
                      />
                    </button>
                    {isMenuOpen && (
                      <ul
                        ref={dropdownRef}
                        className="p-2 absolute top-full left-0 z-10 w-full mt-1 bg-white border border-anti-flash-lite rounded-md shadow-md"
                      >
                        {GENDER.map((item, index) => (
                          <li
                            key={index}
                            className="px-2 py-2 flex items-center space-x-2 rounded-md cursor-pointer transition-colors duration-300 ease-in-out hover:bg-04-anti-flash-white focus:bg-white"
                            onClick={() => handleSelectGender(item)}
                          >
                            <img src={item.icon} alt="" className="w-5 h-5" />
                            <span className="font-semibold text-sm 2xl:text-base">
                              {item.value}
                            </span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex justify-end gap-[12px]">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    methods.reset();
                  }}
                  className="border-2 border-solid px-[20px] h-[36px] 2xl:h-[40px] text-sm 2xl:text-base rounded-[8px] font-black !text-[#084F4B] border-[#084F4B] hover:opacity-80"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={error || isLoading}
                  className="bg-[#084F4B] px-[20px] h-[36px] 2xl:h-[40px] text-sm 2xl:text-base rounded-[8px] font-black !text-white hover:opacity-80"
                >
                  {isLoading ? "Updating..." : "Update Profile"}
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      {renderModal()}
    </div>
  );
};
