import { Switch } from "@chakra-ui/react";
import ArrowRightIcon from "../../assets/arrow-right-icon.svg";
import { useEffect, useState } from "react";
import { fetchListSettings, updateSettings } from "../../apis/notification.api";

const NotificationSettings = () => {
  const [listSettings, setListSettings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeSetting = async (typeId, option) => {
    try {
      setIsLoading(true);
      await updateSettings({ typeId, option: !option });
      setListSettings((pre) =>
        pre.map((item) =>
          item.id === typeId ? { ...item, option: !option } : item
        )
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchListSetting = async () => {
      try {
        const data = await fetchListSettings();
        if (data) {
          setListSettings(data);
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };
    fetchListSetting();
  }, []);

  return (
    <div className="m-auto px-5 py-5 w-full md:py-8">
      <div className="flex items-center gap-[12px]">
        <p className="text-sm 2xl:text-base">Home</p>
        <img src={ArrowRightIcon} alt="arrow" />
        <p className="!text-[#084F4B] text-sm 2xl:text-base">Settings</p>
      </div>
      <div className="mt-[24px] xl:max-w-[70%] max-w-[100%]">
        <p className="text-[28px] 2xl:text-[32px] !font-montserrat font-extrabold">
          Notifications
        </p>
        <p className="!text-02-dim-gray text-sm 2xl:text-base mt-[4px]">
          Manage your notifications.
        </p>
        <div className="mt-[32px] flex">
          <div className="w-[30%]">
            <p className="font-semibold text-sm 2xl:text-base">
              Comment notifications
            </p>
          </div>
          <div className="w-[70%] flex flex-col gap-[24px]">
            {listSettings?.map((elm) => (
              <div className="flex items-start gap-[24px]" key={elm.id}>
                <Switch
                  variant={"dark"}
                  size="md"
                  mt={"0.25rem"}
                  isChecked={elm.option}
                  onChange={() => handleChangeSetting(elm.id, elm.option)}
                  isDisabled={isLoading}
                />
                <div>
                  <p className="text-sm 2xl:text-base font-semibold">
                    {elm.name}
                  </p>
                  <p className="!text-02-dim-gray text-xs 2xl:text-sm">
                    {elm.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
