import React, { useState } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import CopyGreenIcon from "../../assets/copy-green-icon.svg";
import FacebookIcon from "../../assets/facebook-icon.svg";
import LinkedinIcon from "../../assets/linkedin-icon.svg";
import PinterestIcon from "../../assets/pinterest-icon.svg";
import TwitterIcon from "../../assets/twitter-icon.svg";
import WhatsappIcon from "../../assets/whatsapp-icon.svg";
import CloseIcon from "../../assets/close-modal-icon.svg";
import useSearchParams from "../../hooks/router/useSearchParams";

function ShareContentModal({
  isOpen,
  Icon,
  onClose,
  imgUrl,
  srcImg,
  contentCategory,
  url,
}) {
  const shareUrl = url || window.location.href;
  const idTwyg = useSearchParams().searchParams.id;
  const [isCopy, setIsCopy] = useState(false);

  const handleCopy = (value) => {
    if (!isCopy) {
      setIsCopy(true);
      window.dataLayer.push({
        event: "share",
        twyg_id: idTwyg,
        twyg_category: contentCategory,
        share_medium: value,
      });
      setTimeout(() => {
        setIsCopy(false);
      }, 3000);
      if (value) navigator.clipboard.writeText(value);
    }
  };

  const handleTrackingShare = (platform) => {
    window.dataLayer.push({
      event: "share",
      twyg_id: idTwyg,
      twyg_category: contentCategory,
      share_medium: platform,
    });
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-[100vh] flex items-center justify-center z-10 overflow-hidden">
          <div
            className={`fixed top-0 left-0 w-full h-full bg-[#333333] bg-opacity-70`}
            onClick={onClose}
          ></div>
          <div className="bg-white rounded-xl max-w-[480px] w-full p-12 text-center relative">
            {Icon && (
              <div className="mb-[12px] flex justify-center">
                <Icon alt="" />
              </div>
            )}
            {srcImg && (
              <div className="mb-[12px] flex justify-center">
                <img src={srcImg} alt="icon" className="" />
              </div>
            )}
            <h1 className="text-[32px] font-black text-[#084f4b] mb-8">
              Share this Twyg!
            </h1>
            <div className="flex justify-center gap-4 mb-4">
              <FacebookShareButton
                url={shareUrl}
                hashtag="#twygs"
                onClick={() => handleTrackingShare("facebook")}
              >
                <img
                  src={FacebookIcon}
                  alt="icon"
                  className="hover:opacity-80 cursor-pointer w-10 h-10"
                />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                title="I'm thrilled to share my latest Twyg with you! See what's new"
                onClick={() => handleTrackingShare("twitter")}
              >
                <img
                  src={TwitterIcon}
                  alt="icon"
                  className="hover:opacity-80 cursor-pointer w-10 h-10"
                />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                onClick={() => handleTrackingShare("linkedin")}
              >
                <img
                  src={LinkedinIcon}
                  alt="icon"
                  className="hover:opacity-80 cursor-pointer w-10 h-10"
                />
              </LinkedinShareButton>
              <WhatsappShareButton
                url={shareUrl}
                windowWidth={700}
                windowHeight={600}
                separator=" "
                title="I'm thrilled to share my latest Twyg with you! See what's new"
                onClick={() => handleTrackingShare("whatsapp")}
              >
                <img
                  src={WhatsappIcon}
                  alt="icon"
                  className="hover:opacity-80 cursor-pointer w-10 h-10"
                />
              </WhatsappShareButton>
              <PinterestShareButton
                description="twygs.io"
                url={shareUrl}
                media={encodeURIComponent(imgUrl)}
                onClick={() => handleTrackingShare("pinterest")}
              >
                <img
                  src={PinterestIcon}
                  alt="icon"
                  className="hover:opacity-80 cursor-pointer w-10 h-10"
                />
              </PinterestShareButton>
            </div>
            <div className="bg-05-seasalt-white h-64px p-4 flex items-center justify-between rounded-xl">
              <p className="w-257px truncate text-base font-semibold text-jet-black max-w-[80%]">
                {shareUrl || window.location.href}
              </p>
              <div
                className="flex items-center gap-8px cursor-pointer gap-2"
                onClick={() => handleCopy(shareUrl)}
              >
                <p className="text-sm font-black text-go-green">
                  {isCopy ? "Copied" : "Copy"}
                </p>
                <img src={CopyGreenIcon} alt="" />
              </div>
            </div>
            <img
              src={CloseIcon}
              onClick={onClose}
              alt="close modal"
              className="absolute right-[15px] top-[15px] cursor-pointer"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default ShareContentModal;
