/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import Panzoom from "@panzoom/panzoom";

import PaginationComponent from "../../Pagination";
import HTMLFlipBook from "react-pageflip";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { getRandomObjectWithRatio } from "../../../constants/ads";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import FullScreenIcon from "../../../assets/full-screen-icon.svg";
import MinusIcon from "../../../assets/minus.svg";
import PlusIcon from "../../../assets/plus.svg";
import PagesComponent from "./page-component";

import { isMobile } from "../../../utils/helper.util";
import { isAndroi, isIOS } from "../../../constants";

const ShowContentPdfDesktopComponent = ({ twygs, inpage, adRandomDefault }) => {
  const refHeight = useRef();
  const pageButtonContainerRef = useRef();
  const isShowAd = useRef(false);
  const calculateDimentions = (fullScreen) => {
    const img = new Image();
    const src = twygs.contentUrlList[0];
    const container = document.getElementById("main-content");
    img.src = src; // Load the first page to get dimensions
    img.onload = () => {
      let width, height;
      const aspectRatio = img.height / img.width;

      if (fullScreen) {
        if (aspectRatio > 1 && !isMobile()) {
          width = container.clientWidth;
        } else {
          width = window.innerWidth;
        }
      } else {
        width = container.clientWidth;
      }

      height = isMobile()
        ? (width / 2) * aspectRatio
        : ((width - 96) / 2) * aspectRatio;

      setWidth(width);
      setHeight(height);
    };
  };
  useEffect(() => {
    document.getElementById("content").addEventListener(
      "wheel",
      function (event) {
        event.preventDefault();
      },
      { passive: false }
    );

    if (twygs.contentUrlList[0]) {
      calculateDimentions(false);
    }
  }, [twygs.contentUrlList]);
  const refLoadAds = useRef();
  const firstJumbPage = useRef();
  const handle = useFullScreenHandle();
  const [numPages, setNumPages] = useState(twygs.contentUrlList.length);
  const currentPage = useMemo(
    () =>
      parseInt(
        JSON.parse(localStorage.getItem("savedPdfPages") || "{}")[twygs.id] ??
          1,
        10
      ),
    []
  );
  const [pageNumber, setPageNumber] = useState(
    currentPage >= numPages ? 1 : currentPage
  );
  const pageRef = useRef(pageNumber);

  const previousIndexRef = useRef(
    currentPage ? currentPage + (isMobile() ? 1 : 3) : isMobile() ? 2 : 4
  );
  const indexAdsRef = useRef(
    previousIndexRef.current > numPages
      ? isMobile()
        ? 2
        : 4
      : previousIndexRef.current
  );

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [darkMode, setDarkMode] = useState(true);

  const [width, setWidth] = useState();
  const [height, setHeight] = useState(isMobile() ? 300 : 400);
  const refSizePdf = useRef({
    defaultHeight: 0,
    fullpageHeight: 0,
    defaultWidth: 0,
    fullpageWidth: 0,
  });

  const [cloneElm, setCloneElm] = useState();
  const flipBookRef = useRef(null);
  const [pageRendered, setPageRendered] = useState(
    Array.from(
      { length: 3 },
      (_, i) => i + (currentPage >= numPages ? 1 : currentPage)
    )
  );

  const [scaleValue, setScaleValue] = useState();

  const registerEventZoom = () => {
    const htmlflipbook = document.querySelector(".html-flip");
    const panzoom = Panzoom(htmlflipbook, {
      minScale: 1,
      maxScale: 6,
    });

    window.panzoomInstance = panzoom;

    const onPanzoomChange = (event) => {
      const { scale } = event.detail;
      setScaleValue(`${Math.trunc(scale * 100)}%`);
      resetPosition();
    };

    htmlflipbook.addEventListener("panzoomchange", onPanzoomChange);

    htmlflipbook.parentElement.addEventListener("wheel", function (e) {
      window.panzoomInstance.zoomWithWheel(e, {
        // step: isMobile() ? 0.3 : 0.15,
      });
    });

    let clickCount = 0;
    let singleClickTimer;

    htmlflipbook.addEventListener(
      "click",
      function (e) {
        clickCount++;
        if (clickCount === 1) {
          singleClickTimer = setTimeout(function () {
            clickCount = 0;
          }, 400);
        } else if (clickCount >= 2) {
          clearTimeout(singleClickTimer);
          clickCount = 0;
          window.panzoomInstance.zoomIn({
            animate: true,
            // focal: {
            //   x: e.offsetX,
            //   y: e.offsetY,
            // },
          });
        }
      },
      false
    );

    htmlflipbook.setAttribute("listener", "true");
  };

  useEffect(() => {
    const htmlflipbook = document.querySelector(".html-flip");
    if (htmlflipbook) {
      if (htmlflipbook.getAttribute("listener") !== "true") {
        registerEventZoom();
      }
    }
  });

  const zoomIn = () => {
    window.panzoomInstance.zoomIn();
  };

  const zoomIsAvailable = () => {
    return Boolean(window.panzoomInstance) || false;
  };

  const zoomOut = () => {
    window.panzoomInstance.zoomOut();
  };

  const resetPosition = () => {
    const panzoomTransformData = window.panzoomInstance;
    const scale = panzoomTransformData.getScale();
    const pan = panzoomTransformData.getPan();
    if (scale <= 1.0005 && (pan.x !== 0 || pan.y !== 0)) {
      resetZoom();
    }
  };

  function resetZoom() {
    if (window.panzoomInstance) {
      window.panzoomInstance.reset();
    }
  }

  const enterFullscreen = () => {
    if (window.panzoomInstance.getScale() > 1) {
      resetZoom();
    }
    calculateDimentions(true);

    setIsFullscreen(true);
    handle.enter();
    const currentPage = pageNumber;
    handleRenderSuccess(currentPage);
    setTimeout(() => {
      flipBookRef.current?.pageFlip()?.turnToPage(currentPage - 1);
    }, 500);
  };

  const exitFullscreen = () => {
    if (window.panzoomInstance.getScale() > 1) {
      resetZoom();
      // setValue("100%");
    }

    calculateDimentions(false);

    setIsFullscreen(false);
    handle.exit();
    const currentPage = pageNumber;
    setTimeout(() => {
      flipBookRef.current?.pageFlip()?.turnToPage(currentPage - 1);
    }, 500);
  };

  const reportChange = (event) => {
    if (!event && zoomIsAvailable()) {
      if (window.panzoomInstance.getScale() > 1) {
        resetZoom();
      }

      setIsFullscreen(false);
      calculateDimentions(false);
      handle.exit();
      const currentPage = pageNumber;
      setTimeout(() => {
        flipBookRef.current?.pageFlip()?.turnToPage(currentPage - 1);
      }, 500);
    }
  };

  const isRendered = (num) => {
    return !!pageRendered.find((elm) => elm === num);
  };

  const handleChangePage = (page) => {
    if (!isRendered(page)) setPageRendered([...pageRendered, page]);
    pageRef.current = page;
    resetZoom();

    const flipToPage = (newPage) => {
      flipBookRef?.current?.pageFlip()?.turnToPage(newPage - 1);
    };

    // if (isMobile()) {
    //   setPageNumber(page);
    //   flipToPage(page);
    // } else {
    let newPage;
    if (page % 2 !== 0) {
      newPage = page;
    } else {
      newPage = page - 1;
    }
    setPageNumber(newPage);
    flipToPage(newPage);
    // }
  };

  const onFlip = (e) => {
    const newPageNumber = parseInt(e.data) + 1;
    setPageNumber(newPageNumber);

    const currentPdfId = twygs.id;
    const savedPages = JSON.parse(
      localStorage.getItem("savedPdfPages") || "{}"
    );
    savedPages[currentPdfId] = newPageNumber;
    localStorage.setItem("savedPdfPages", JSON.stringify(savedPages));
  };

  const updatePageRendered = (page) => {
    const checkCurrentPage = page <= currentPage && page > 1;
    const pageToAdd = checkCurrentPage ? page - 1 : page + 1;
    const arrNextLoad = checkCurrentPage
      ? [page - 1, page - 2, page - 3]
      : [page + 1, page + 2, page + 3];

    if (!pageRendered.find((elm) => elm === pageToAdd)) {
      setTimeout(() => {
        setPageRendered((prev) => [...prev, ...arrNextLoad]);
      }, 2000);
    }
  };

  const handleRenderSuccess = (page) => {
    if (refHeight.current) {
      if (
        height &&
        parseFloat(height) !== height &&
        parseFloat(height) !==
          (isFullscreen
            ? refSizePdf.current?.fullpageHeight
            : refSizePdf.current?.defaultHeight) &&
        (isFullscreen
          ? refSizePdf.current?.fullpageHeight === 0
          : refSizePdf.current?.defaultHeight === 0)
      ) {
        if (!isFullscreen) {
          refSizePdf.current.defaultHeight = parseFloat(height);
        } else refSizePdf.current.fullpageHeight = parseFloat(height);
        setHeight(parseFloat(height));
      }
      if (
        pageNumber === currentPage &&
        page === currentPage &&
        currentPage !== 1 &&
        !firstJumbPage.current
      ) {
        handleChangePage(currentPage);
        firstJumbPage.current = true;
      }

      updatePageRendered(page);

      if (inpage && !refLoadAds.current) {
        // refLoadAds.current = true;
        return;
        // let lastWidth = width;
        // let lastHeight = height;

        // const ratioCheck = isMobile()
        //   ? lastWidth / lastHeight
        //   : lastWidth / 2 / lastHeight;
        // const typeRandom = "LANDSCAPE";
        // // ratioCheck > 1 ? "LANDSCAPE" : ratioCheck < 1 ? "PORTRAIT" : "SQUARE";
        // const assetRandom = getRandomObjectWithRatio(adRandomDefault.assets, [
        //   typeRandom,
        // ]);
        // if (typeRandom !== "LANDSCAPE" || ratioCheck > 1) {
        //   lastHeight = lastHeight - (isMobile() || lastWidth < 410 ? 0 : 36);
        //   lastWidth = lastWidth - (isMobile() || lastWidth < 410 ? 0 : 60);
        // }

        // const elmContentAdProvider = cloneElm.querySelector("#content-ad");
        // cloneElm.querySelector("#content-ad").style.border = "none";
        // cloneElm.style.width = `100%`;
        // cloneElm.style.position = "unset";
        // cloneElm.style.background = "#fff";
        // cloneElm.style.borderRadius = "0px";
        // cloneElm.style.overflow = "unset";
        // cloneElm.style.height = `${lastHeight}px`;
        // cloneElm.querySelector("#twyg-cta-btn").style.margin = "0px auto";
        // cloneElm.querySelector("#twyg-cta-btn").style.width = "100%";
        // cloneElm.style.left = "unset";
        // cloneElm.style.transform = "unset";
        // const elmContentAd = cloneElm.querySelector("div");
        // const elmContentAdChild = elmContentAd.querySelector("div");
        // elmContentAd.style.width = `100%`;
        // elmContentAd.querySelector("img").style.objectFit = "cover";
        // elmContentAd.querySelector("img").style.width = "auto";
        // elmContentAd.querySelector("img").src = assetRandom.url;
        // elmContentAd.querySelector("img").style.borderRadius = "0px";
        // const contentAdInfoContainer = elmContentAd.querySelector(
        //   "#content-ad-info-container"
        // );
        // const innerDiv = contentAdInfoContainer
        //   ?.querySelector("div")
        //   ?.querySelector("div");
        // const adProvider = document.getElementById("add-ad-later");
        // if (ratioCheck > 1) {
        //   elmContentAdProvider.style.borderRadius =
        //     isMobile() || lastWidth < 410 ? "0px" : "8px";
        //   contentAdInfoContainer.style.height = `${lastHeight * 0.33}px`;
        //   contentAdInfoContainer.style.width = `"100%"`;
        //   contentAdInfoContainer.style.alignItems = "center";
        //   innerDiv.style.maxWidth = `${lastWidth - 200}px`;
        //   elmContentAdChild.style.maxWidth = `100%`;
        //   elmContentAdChild.style.borderRadius = "0px";
        //   elmContentAdChild.style.height = `${lastHeight * 0.67}px`;
        //   adProvider.style.padding =
        //     isMobile() || lastWidth < 400 ? "0px" : "18px 30px";
        //   if (lastWidth < 370 && !isAndroi() && !isIOS())
        //     contentAdInfoContainer.className = "dekstop-mini-width";
        // } else if (ratioCheck < 1) {
        //   elmContentAdProvider.style.borderRadius = "0px";
        //   contentAdInfoContainer.className = isMobile()
        //     ? "mobile-ad-info-container"
        //     : "desktop-ad-info-container";
        //   contentAdInfoContainer.style.padding =
        //     lastWidth < 370 ? "0px" : "30px 20px";
        //   contentAdInfoContainer.style.gap = "24px";
        //   contentAdInfoContainer.style.flexFlow = "column";
        //   contentAdInfoContainer.style.height = `${lastHeight * 0.58}px`;
        //   contentAdInfoContainer.style.alignItems = "center";
        //   contentAdInfoContainer.style.justifyContent = "space-around";
        //   contentAdInfoContainer.querySelector("#ad-logo-content").style.width =
        //     "100%";
        //   contentAdInfoContainer.querySelector(
        //     "#ad-logo-content"
        //   ).style.display = "flex";
        //   contentAdInfoContainer.querySelector("#ad-logo-content").style.gap =
        //     "20px";
        //   contentAdInfoContainer.querySelector(
        //     "#ad-logo-content"
        //   ).style.flexFlow = "column";
        //   contentAdInfoContainer.querySelector("#ad-logo").style.minWidth =
        //     "60px";
        //   contentAdInfoContainer.querySelector("#ad-logo").style.minHeight =
        //     "60px";
        //   innerDiv.style.maxWidth = `100%`;
        //   innerDiv.style.gap = `20px`;
        //   innerDiv.className = `text-align-container ${
        //     isMobile() ? "size-text-mobile" : "size-text-desktop"
        //   }`;
        //   contentAdInfoContainer.querySelector(
        //     "#learn-more-style"
        //   ).style.width = "100%";
        //   contentAdInfoContainer.querySelector("#learn-more-style").className =
        //     "learn-more-style-pdf";
        //   contentAdInfoContainer.querySelector(
        //     "#learn-more-style"
        //   ).style.marginLeft = "0px";
        //   elmContentAdChild.style.borderRadius = "4px";
        //   elmContentAdChild.style.height = `${lastHeight * 0.42}px`;
        // }
        // if (isMobile()) {
        //   elmContentAd.querySelector("#learn-more-style").style.margin =
        //     "14px 0px 0px 0px";
        //   elmContentAd.querySelector(
        //     "#content-ad-info-container"
        //   ).style.display = "block";
        //   elmContentAd
        //     .querySelector("#content-ad-info-container")
        //     .querySelector("div")
        //     .querySelector("div").style.maxWidth = "100%";
        // }
        // cloneElm && adProvider.appendChild(cloneElm);
      }
    }
  };

  const convertName = () => {
    if (!twygs) return "";
    const splits = twygs.contentUrlList[0].split("/");
    return decodeURIComponent(
      splits[splits.length - 1].substring(
        0,
        splits[splits.length - 1].indexOf("?AWSAccessKeyId") - 7
      )
    );
  };

  useEffect(() => {
    if (inpage) {
      const elmAdContainer = document.getElementById("twyg-ad-container");
      if (elmAdContainer) {
        let clonedElement = elmAdContainer;
        elmAdContainer.remove();
        var closeButton = clonedElement.querySelector(
          "#twyg-btn-close-ad-provider"
        );
        if (closeButton) {
          closeButton.remove();
        }
        setCloneElm(clonedElement);
        setNumPages(twygs.contentUrlList.length);
      }
    } else {
      setNumPages(twygs.contentUrlList.length);
    }
  }, [inpage]);

  if (!width) return null;
  return (
    <FullScreen handle={handle} className="w-full" onChange={reportChange}>
      <div className={`w-full ${isFullscreen ? "full-screenable-node" : ""}`}>
        <div
          className={`relative w-full flex flex-col justify-between pb-1 ${
            width / 2 < 460 ? "md:!min-h-[300px]" : ""
          } min-h-[300px] md:min-h-[400px] `}
          style={{
            borderRadius: "20px 20px 0 0",
            background: `${darkMode ? "#808080" : "#F2F3F7"}`,
          }}
        >
          {twygs ? (
            // <div className="h-full ">
            <>
              {numPages && (
                <div
                  className={`w-full flex justify-center`}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  <div
                    className="w-full bg-[#333] h-10 md:h-[52px] flex gap-4 items-center justify-between px-4 shadow-[rgba(0, 0, 0, 0.15)]"
                    style={{
                      borderRadius: "20px 20px 0 0",
                    }}
                  >
                    <div className="absolute left-1/2 transform -translate-x-1/2">
                      <div className="flex h-[29px] items-center">
                        <p className="!text-06-seasalt-white font-normal text-[12px] md:text-base">{`${pageNumber}${
                          isMobile() ? "" : "-" + (pageNumber + 1)
                        }/${numPages}`}</p>
                        <div className="h-full w-[1.2px] bg-02-dim-gray mx-[15px] md:mx-[30px]" />

                        <div className="flex items-center gap-2 md:gap-5 h-full">
                          <button className="!text-white" onClick={zoomOut}>
                            <img
                              src={MinusIcon}
                              alt="Zoom out"
                              className="min-w-5 min-h-5"
                            />
                          </button>
                          <input
                            type="text"
                            value={scaleValue ?? ""}
                            readOnly
                            id="percent-input"
                            onChange={() => {}}
                            className="max-w-[50px] text-center p-1 h-full text-[14px] !text-06-seasalt-white bg-[#00000040] !border-none outline-none rounded-none"
                          />
                          <button className="!text-white" onClick={zoomIn}>
                            <img
                              src={PlusIcon}
                              alt="Zoom in"
                              className="min-w-5 min-h-5"
                            />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center gap-3 ml-auto">
                      {!isMobile() || isAndroi() ? (
                        <div
                          onClick={() =>
                            isFullscreen ? exitFullscreen() : enterFullscreen()
                          }
                          className="p-2 rounded-md hover:bg-[#F8FAFC2E] transition-all duration-150 cursor-pointer"
                        >
                          <img
                            src={FullScreenIcon}
                            className="min-w-[20px] min-h-[20px]"
                            alt="full screen"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        onClick={() => setDarkMode((pre) => !pre)}
                        className="p-1 rounded-md hover:bg-[#F8FAFC2E] transition-all duration-150 cursor-pointer"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#fff"
                          className="w-[20px] h-[20px]"
                          style={{ transform: "rotate(40deg)" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                ref={refHeight}
                className={`w-full flex ${
                  width / 2 < 350 ? "md:px-8" : ""
                } flex justify-center md:py-3 items-center min-h-[300px] relative pointer-events-auto `}
                style={{
                  height: `${isFullscreen ? "calc(100vh - 160px)" : "100%"}`,
                }}
              >
                <HTMLFlipBook
                  id="htmlflipbook"
                  key={width}
                  width={
                    isMobile() || isAndroi() || isIOS()
                      ? width / 2
                      : (width - 96) / 2
                  }
                  height={height}
                  size={isMobile() ? "fixed" : "stretch"}
                  usePortrait={isMobile()}
                  minWidth={250}
                  maxWidth={
                    isMobile() || isAndroi() || isIOS()
                      ? width / 2
                      : (width - 96) / 2
                  }
                  onFlip={onFlip}
                  className="html-flip flex justify-center items-center"
                  maxHeight={height * 2}
                  maxShadowOpacity={0.01}
                  flippingTime={800}
                  useMouseEvents={false}
                  ref={flipBookRef}
                >
                  {Array.from({ length: numPages }, (_, pNum) => {
                    const pageIndex =
                      !inpage || pNum + 1 < indexAdsRef.current ? pNum : pNum;
                    const adjustedPageNumber =
                      !inpage || pNum + 1 < indexAdsRef.current
                        ? pNum + 1
                        : pNum + 1;
                    const isAdVisible =
                      indexAdsRef.current === pNum + 1 && inpage;
                    isShowAd.current = isAdVisible;

                    const pageWidth =
                      isMobile() || isAndroi() || isIOS()
                        ? width / 2
                        : (width - 96) / 2;

                    return (
                      <PagesComponent
                        key={pNum}
                        index={pNum}
                        height={height}
                        url={twygs.contentUrlList[pageIndex]}
                        width={pageWidth}
                        // isShowAd={isAdVisible}
                        // pageNumber={adjustedPageNumber}
                        handleRenderSuccess={handleRenderSuccess}
                      />
                    );
                  })}
                </HTMLFlipBook>
              </div>
            </>
          ) : (
            <div
              role="status"
              className={`w-full min-h-[${height}px] rounded animate-pulse`}
            >
              <div
                className={`flex w-full items-center justify-center min-h-[${height}px] mb-4 bg-gray-300 rounded dark:bg-gray-700`}
              >
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
            </div>
          )}
          {numPages > 0 ? (
            <div
              className="mt-2 md:mb-2"
              style={{
                zIndex: isAndroi() && isFullscreen ? 4 : 1,
                background: `${darkMode ? "#808080" : "#F2F3F7"}`,
              }}
            >
              <div className="relative">
                <PaginationComponent
                  currentPage={pageNumber}
                  totalPages={numPages}
                  onChangePage={handleChangePage}
                  hiddenBgDefault
                  darkMode={darkMode}
                  showTwoPage={true}
                />
                {isMobile() ? (
                  <div
                    className="absolute flex items-center top-1/2 w-full px-1 justify-between"
                    style={{ transform: "translateY(-50%)", zIndex: 1 }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="white"
                      className="w-5 h-5"
                      style={{ zIndex: 3 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        resetZoom();

                        if (pageRef.current - 1 >= 1) {
                          if (!pageRendered.includes(pageRef.current - 1)) {
                            setPageRendered((prev) => [
                              ...prev,
                              pageRef.current - 1,
                              pageRef.current - 2,
                              pageRef.current - 3,
                            ]);
                          }
                          pageRef.current -= 1;
                          flipBookRef.current.pageFlip().flipPrev();
                        }
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5L8.25 12l7.5-7.5"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="white"
                      className="w-5 h-5"
                      style={{ zIndex: 3 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        resetZoom();
                        if (pageRef.current + 1 <= numPages) {
                          if (!pageRendered.includes(pageRef.current + 1)) {
                            setPageRendered((prev) => [
                              ...prev,
                              pageRef.current + 1,
                              pageRef.current + 2,
                              pageRef.current + 3,
                            ]);
                          }
                          pageRef.current += 1;
                          flipBookRef.current.pageFlip().flipNext();
                        }
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 4.5l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {!isMobile() ? (
                <div
                  className={`absolute w-full top-1/2 -translate-y-[40%] flex items-center justify-between ${
                    width / 2 < 350 ? "md:!px-0" : ""
                  } md:px-2 `}
                  style={{ zIndex: 1, pointerEvents: "none" }}
                  id="page-buttons-container-desktop"
                  ref={pageButtonContainerRef}
                >
                  <button
                    className="!border-0 !bg-transparent"
                    style={{ pointerEvents: "auto" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      resetZoom();
                      if (currentPage > 1 && !isRendered(currentPage - 2))
                        setPageRendered([...pageRendered, currentPage - 2]);
                      flipBookRef.current.pageFlip().flipPrev();
                    }}
                  >
                    <svg
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`chevron-icon ${
                        darkMode ? "mode-dark" : ""
                      } h-6 w-6 md:h-8 md:w-8 xl:h-10 xl:w-10`}
                    >
                      <path d="M32.7063 7.14727L24.9234 18.2584C24.261 19.2534 24.261 20.7459 24.9234 21.741L32.8719 32.8521C34.0311 34.5105 32.8719 36.6663 30.8848 36.6663H19.9555C18.4652 36.6663 17.1404 36.003 16.1469 34.6763L7.53596 22.5702C6.3768 21.0776 6.3768 18.9217 7.53596 17.4292L16.1469 5.32306C16.9748 3.99636 18.4652 3.33301 19.9555 3.33301H30.7192C32.7063 3.33301 33.8655 5.48889 32.7063 7.14727Z" />
                    </svg>
                  </button>
                  <button
                    style={{ pointerEvents: "auto" }}
                    className="!border-0 !bg-transparent z-10"
                    onClick={(e) => {
                      e.stopPropagation();
                      resetZoom();
                      if (
                        currentPage < numPages &&
                        !isRendered(currentPage + 2)
                      )
                        setPageRendered([...pageRendered, currentPage + 2]);
                      flipBookRef.current.pageFlip().flipNext();
                    }}
                  >
                    <svg
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={`chevron-icon ${
                        darkMode ? "mode-dark" : ""
                      } h-6 w-6 md:h-8 md:w-8 xl:h-10 xl:w-10`}
                    >
                      <path d="M7.29362 7.14727L15.0766 18.2584C15.7389 19.2534 15.7389 20.7459 15.0766 21.741L7.12802 32.8521C5.96886 34.5105 7.12802 36.6663 9.11516 36.6663H20.0444C21.5347 36.6663 22.8595 36.003 23.8531 34.6763L32.464 22.5702C33.6231 21.0776 33.6231 18.9217 32.464 17.4292L23.8531 5.32306C23.0251 3.99636 21.5347 3.33301 20.0444 3.33301H9.28075C7.29362 3.33301 6.13446 5.48889 7.29362 7.14727Z" />
                    </svg>
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : null}
        </div>
      </div>
    </FullScreen>
  );
};

export default ShowContentPdfDesktopComponent;
